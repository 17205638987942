// Generated by Framer (8857874)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {COVYP8d2j: {hover: true}};

const cycleOrder = ["COVYP8d2j", "aTZHR1fOK"];

const serializationHash = "framer-fM74f"

const variantClassNames = {aTZHR1fOK: "framer-v-v5yyhs", COVYP8d2j: "framer-v-1ygx6qq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "COVYP8d2j", "Variant 2": "aTZHR1fOK"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "COVYP8d2j"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "COVYP8d2j", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ygx6qq", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"COVYP8d2j"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"COVYP8d2j-hover": {"data-framer-name": undefined}, aTZHR1fOK: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 597, intrinsicWidth: 597, pixelHeight: 1194, pixelWidth: 1194, sizes: "61px", src: "https://framerusercontent.com/images/rBGovUpLAuamOEqFXLybE8TbmY.png?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/rBGovUpLAuamOEqFXLybE8TbmY.png?scale-down-to=512 512w,https://framerusercontent.com/images/rBGovUpLAuamOEqFXLybE8TbmY.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/rBGovUpLAuamOEqFXLybE8TbmY.png 1194w"}} className={"framer-94qy4t"} data-framer-name={"Group_8895"} layoutDependency={layoutDependency} layoutId={"ZJGawCCyt"} transformTemplate={transformTemplate1} {...addPropertyOverrides({"COVYP8d2j-hover": {background: {alt: "", fit: "fill", intrinsicHeight: 597, intrinsicWidth: 597, pixelHeight: 1194, pixelWidth: 1194, sizes: "81px", src: "https://framerusercontent.com/images/4b7vUS20TyoLWSF0hnDgfwHyx5w.png?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/4b7vUS20TyoLWSF0hnDgfwHyx5w.png?scale-down-to=512 512w,https://framerusercontent.com/images/4b7vUS20TyoLWSF0hnDgfwHyx5w.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/4b7vUS20TyoLWSF0hnDgfwHyx5w.png 1194w"}}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-fM74f.framer-r0d8jv, .framer-fM74f .framer-r0d8jv { display: block; }", ".framer-fM74f.framer-1ygx6qq { cursor: pointer; height: 90px; overflow: hidden; position: relative; width: 90px; }", ".framer-fM74f .framer-94qy4t { aspect-ratio: 1 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 61px); left: 50%; overflow: visible; position: absolute; top: 50%; width: 61px; }", ".framer-fM74f.framer-v-v5yyhs.framer-1ygx6qq { aspect-ratio: 1 / 1; cursor: unset; height: var(--framer-aspect-ratio-supported, 90px); }", ".framer-fM74f.framer-v-1ygx6qq.hover.framer-1ygx6qq { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 90px); }", ".framer-fM74f.framer-v-1ygx6qq.hover .framer-94qy4t { height: var(--framer-aspect-ratio-supported, 81px); width: 81px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 90
 * @framerIntrinsicWidth 90
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"aTZHR1fOK":{"layout":["fixed","fixed"]},"K3KMI71EK":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerh4JKTWEXE: React.ComponentType<Props> = withCSS(Component, css, "framer-fM74f") as typeof Component;
export default Framerh4JKTWEXE;

Framerh4JKTWEXE.displayName = "Illustration/Check";

Framerh4JKTWEXE.defaultProps = {height: 90, width: 90};

addPropertyControls(Framerh4JKTWEXE, {variant: {options: ["COVYP8d2j", "aTZHR1fOK"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerh4JKTWEXE, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})